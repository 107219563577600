import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes = [

    {
        path: '/',
        name: 'home-page',
        component: () => import('@/view/home-page/index.vue'),
        meta: {
            title: '首页',
            requireAuth: false
        }
    }
    
    
]
const router = createRouter({
    history: createWebHistory(),
    // history: createWebHashHistory(process.env.BASE_URL),
    routes //上面的路由数组
})
export default router


