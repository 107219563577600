import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import './utils/axios'
import axios from 'axios';
import VueAxios from "vue-axios";
import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';

const app = createApp(App);
app.use(TDesign)
app.use(VueAxios, axios)
app.use(router)
app.mount('#app')
