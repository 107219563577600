<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
     
    };
  },


  watch: {
    $route(to, from) {
      // 监听路由变化, 实现类似 小程序的 onShow 事件
      // if (to.path === '/') {
      //   if (localStorage.getItem("userWebLoginData")) {
      //     this.userWebLoginData = JSON.parse(localStorage.getItem("userWebLoginData"))
      //     this.getCategoriesJoin()
      //   }
      // }
    }
  },


  mounted() {

  },
  beforeDestroy() {

  },
  methods: {

    
  },
  created() {
   
  }
}
</script>

<style lang="less" scoped>

</style>
